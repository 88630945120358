import React from 'react';
import dynamic from 'next/dynamic';

const About = dynamic(() => import('../components/home/About'), {
  loading: () => <div />,
  ssr: false,
});
const Benefits = dynamic(() => import('../components/home/Benefits'), {
  loading: () => <div />,
  ssr: false,
});
const Contact = dynamic(() => import('../components/home/Contact'), {
  loading: () => <div />,
  ssr: false,
});
const Footer = dynamic(() => import('../components/home/Footer'), {
  loading: () => <div />,
  ssr: false,
});
const Navbar = dynamic(() => import('../components/home/Navbar'), {
  loading: () => <div />,
  ssr: false,
});
const Welcome = dynamic(() => import('../components/home/Welcome'), {
  loading: () => <div />,
  ssr: false,
});

export default function HomePage() {
  return (
    <section className="w-full h-full min-h-screen flex flex-col flex-wrap bg-gray-100">
      <header>
        <Navbar />
      </header>
      <main className="min-h-screen">
        <Welcome />
        <About />
        <Benefits />
        <Contact />
      </main>
      <Footer />
    </section>
  );
}
